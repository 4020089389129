import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import siteSetting from '../../services/settingService';

const FooterComponent = () => {
    const [contact, setContact] = useState();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(()=>{
    
        const settingData= async()=>{
            setIsLoading(true);
            const sitedata = await siteSetting()
            if(sitedata) {
                setContact(sitedata) ;
                setIsLoading(false);
            }else{
                setIsLoading(false);
            }

        }
        settingData()
    },[])
    return (
        <>

            <div className="container-fluid bg-dark text-light mt-5 py-5">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-4 col-md-6">
                            <h4 className="d-inline-block text-primary text-uppercase border-bottom border-5 border-secondary mb-4">Get In Touch</h4>
                           
{
     contact &&
     <>
       <p className="mb-2"><i className="fa fa-map-marker-alt text-primary me-3"></i>{contact.site_address ? contact.site_address : ""}</p>
                                     <p className="mb-2"><i className="fa fa-envelope text-primary me-3"></i>
                                     {contact.site_email ? contact.site_email : ""}
                                     </p>
                                     <p className="mb-2"><i className="fa fa-phone-alt text-primary me-3"></i>{contact.site_phone ? contact.site_phone : ""}</p>
                                     <p className="mb-0"><i className="fa fa-mobile-alt text-primary me-3"></i>{contact.site_mobile ? contact.site_mobile : ""}</p>


     </>


}
                                    
                                   
                    
                          
                            
                           
                           
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <h4 className="d-inline-block text-primary text-uppercase border-bottom border-5 border-secondary mb-4">Quick Links</h4>
                            <div className="d-flex flex-column justify-content-start">
                                <Link className="text-light mb-2" to="/"><i className="fa fa-angle-right me-2"></i>Home</Link>
                                <Link className="text-light mb-2" to="/about-us"><i className="fa fa-angle-right me-2"></i>About Us</Link>
                                <Link className="text-light mb-2" to="/services"><i className="fa fa-angle-right me-2"></i>Our Services</Link>
                                <Link className="text-light mb-2" to="/teams"><i className="fa fa-angle-right me-2"></i>Meet The Team</Link>
                                <Link className="text-light" to="/contact"><i className="fa fa-angle-right me-2"></i>Contact Us</Link>
                            </div>
                        </div>
          
                        <div className="col-lg-4 col-md-6">
                            <h4 className="d-inline-block text-primary text-uppercase border-bottom border-5 border-secondary mb-4">Follow Us</h4>
                            <div className="d-flex">
                            {
                                     contact && contact.site_fb_link &&
                                <a className="btn btn-lg btn-primary btn-lg-square rounded-circle me-2" href={contact.site_fb_link ? contact.site_fb_link : ""} target="_blank"><i className="fab fa-facebook-f"></i></a>


                            }
                                {
                                    contact && contact.site_in_link &&
                                    <a className="btn btn-lg btn-primary btn-lg-square rounded-circle me-2" href={contact.site_in_link ? contact.site_in_link : ""}
                                    target="_blank"><i className="fab fa-instagram"></i></a>

                                }

{
                                  contact && contact.site_tw_link && 
                                  <a className="btn btn-lg btn-primary btn-lg-square rounded-circle me-2" href={contact.site_tw_link ? contact.site_tw_link : ""} 
                                target="_blank"><i className="fab fa-twitter"></i></a>
                                 }
                              
                                 {
                                  contact && contact.site_lin_link && 
                                  <a className="btn btn-lg btn-primary btn-lg-square rounded-circle" href={contact.site_lin_link ? contact.site_lin_link : ""} 
                                target="_blank"><i className="fab fa-linkedin-in"></i></a>
                                 }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-dark text-light border-top border-secondary py-4">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-md-6 text-center text-md-start">
                            {
                                  contact && contact.site_title &&
                            <p className="mb-md-0">&copy; <a className="text-primary" href="#">{contact.site_title}</a>. All Rights Reserved.</p>

                            }
                        </div>
                        <div className="col-md-6 text-center text-md-end">
                            <p className="mb-0">Designed by <a className="text-primary" href="https://blackhawk.edu.np/">Black Hawk Team</a></p>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default FooterComponent
import React, { useEffect } from 'react'
import AppointmentComponent from '../components/common/AppointmentComponent'
import FooterComponent from '../components/common/FooterComponent'
import NavBarComponent from '../components/common/NavBarComponent'
import TopBarComponent from '../components/common/TopBarComponent'
import AboutComponent from '../components/HomeComponent/AboutComponent'
import PackagesComponent from '../components/HomeComponent/PackagesComponent'
import ServicesComponent from '../components/HomeComponent/ServicesComponent'
import SliderComponent from '../components/HomeComponent/SliderComponent'

const HomePage = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
    return (
        <>
            {/* <TopBarComponent />
            <NavBarComponent /> */}
            <SliderComponent />
            <AboutComponent />
            <ServicesComponent />
            <AppointmentComponent />
            {/* <PackagesComponent /> */}
            {/* <FooterComponent /> */}
        </>
    )
}

export default HomePage
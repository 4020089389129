import React from 'react'
import { Link } from 'react-router-dom'

const PageNotFound = () => {

    const card = {

        position: "relative",
        display: "flex",
        flexDirection: "column",
        minWidth: 0,
        wordWrap: "break-word",
        backgroundColor: "#fff",
        backgroundClip: "border-box",
        border: "1px solid rgba(0, 0, 0, 0.04)",
        borderRadius: ".25rem",
        width: "30rem",
        marginBottom: "50px"
    }

    const cardHeader = {
        backgroundColor: "#fff",
        borderBottom: "none"
    }




    return (
        <>
            <div className="row justify-content-center mb-6 wow fadeInUp" data-wow-delay="1s" >
                <div className="col-md-12 col-sm-12">
                    <div className="card shadow-lg border-0 rounded-lg mt-5 mx-auto" style={card}>
                        <h3 className="card-header display-1 text-muted text-center" style={cardHeader}>
                            404
                        </h3>

                        <span className="card-subtitle mb-2 text-muted text-center">
                            Page Not Found
                        </span>

                        <div className="card-body mx-auto">
                            <Link type="button" to="/"
                                className="btn btn-sm btn-info text-white"> Back To Home </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageNotFound
import React, { useEffect, useState } from 'react'
import aboutService from '../../services/aboutServices';
import { imgUrl } from '../common/linkUrl';

const AboutComponent = () => {
    const [about, setAbout] = useState();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(()=>{
    
        const aboutData= async()=>{
            setIsLoading(true);
            const about_data = await aboutService()
            if(about_data) {
                setAbout(about_data) ;
                setIsLoading(false);
            }else{
                setIsLoading(false);
            }

        }
        aboutData()
    },[])
    return (

        <div className="container-fluid py-5">
         
            <div className="container">
                <div className="row gx-5">
                    <div className="col-lg-5 mb-5 mb-lg-0" style={{ minHeight: "500px" }}>
                
                        <div className="position-relative h-100">
                            {
                                about && about.about_img && 
                            <img className="position-absolute w-100 h-100 rounded" src={about.about_img ? `${imgUrl}${about.about_img}` : ""} style={{ objectFit: "cover" }} />

                            }
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="mb-4">
                            <h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">About Us</h5>
                            {
                                 about && about.about_title && 
                                 <h1 className="display-4">{about.about_title}</h1>
                            }
                           
                        </div>
                        {
                            about && about.about_des &&
                                            <>
                                                <span className="mb-4" dangerouslySetInnerHTML={{ __html: about.about_des.slice(0, 500) + '.......' }} >
                                                </span>
                                            </>
                        }
                      
                    </div>
                </div>

                
            </div>
        </div>

    )
}

export default AboutComponent
import React, { useEffect, useState } from 'react'
import servicDataService from '../../services/ourServices';
import { imgUrl } from '../common/linkUrl';

const ServicesComponent = () => {

    const [services, setServices] = useState();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(()=>{
    
        const servicesData= async()=>{
            setIsLoading(true);
            const service = await servicDataService()
            if(service) {
                setServices(service) ;
                setIsLoading(false);
            }else{
                setIsLoading(false);
            }

        }
        servicesData()
    },[])
    return (

        <div className="container-fluid py-5">
     
            <div className="container">
                <div className="text-center mx-auto mb-5" style={{ maxWidth: "500px" }}>
                    <h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">Services</h5>
                    <h1 className="display-4">Excellent Dental Services</h1>
                </div>
                <div className="row g-5">

{
      services && services.length > 0 && services.slice(0, 6).map((service, idx) => (
        <div className="col-lg-4 col-md-6 " key={idx}>
        <div className="service-item shadow bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
            <div className="mb-4">
            <img src={`${imgUrl}${service.service_img}`} alt="dental service" height='100%' width='100%'/>

            </div>
            <h4 className="mb-3">{service.service_name}</h4>
          
        </div>
    </div>

      ))
}
                    
              






                    {/* <div className="col-lg-4 col-md-6">
                        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div className="service-icon mb-4">
                                <i className="fa fa-2x fa-procedures text-white"></i>
                            </div>
                            <h4 className="mb-3">Operation & Surgery</h4>
                            <p className="m-0">Kasd dolor no lorem nonumy sit labore tempor at justo rebum rebum stet, justo elitr dolor amet sit</p>
                            <a className="btn btn-lg btn-primary rounded-pill" href="">
                                <i className="bi bi-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div className="service-icon mb-4">
                                <i className="fa fa-2x fa-stethoscope text-white"></i>
                            </div>
                            <h4 className="mb-3">Outdoor Checkup</h4>
                            <p className="m-0">Kasd dolor no lorem nonumy sit labore tempor at justo rebum rebum stet, justo elitr dolor amet sit</p>
                            <a className="btn btn-lg btn-primary rounded-pill" href="">
                                <i className="bi bi-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div className="service-icon mb-4">
                                <i className="fa fa-2x fa-ambulance text-white"></i>
                            </div>
                            <h4 className="mb-3">Ambulance Service</h4>
                            <p className="m-0">Kasd dolor no lorem nonumy sit labore tempor at justo rebum rebum stet, justo elitr dolor amet sit</p>
                            <a className="btn btn-lg btn-primary rounded-pill" href="">
                                <i className="bi bi-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div className="service-icon mb-4">
                                <i className="fa fa-2x fa-pills text-white"></i>
                            </div>
                            <h4 className="mb-3">Medicine & Pharmacy</h4>
                            <p className="m-0">Kasd dolor no lorem nonumy sit labore tempor at justo rebum rebum stet, justo elitr dolor amet sit</p>
                            <a className="btn btn-lg btn-primary rounded-pill" href="">
                                <i className="bi bi-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div className="service-icon mb-4">
                                <i className="fa fa-2x fa-microscope text-white"></i>
                            </div>
                            <h4 className="mb-3">Blood Testing</h4>
                            <p className="m-0">Kasd dolor no lorem nonumy sit labore tempor at justo rebum rebum stet, justo elitr dolor amet sit</p>
                            <a className="btn btn-lg btn-primary rounded-pill" href="">
                                <i className="bi bi-arrow-right"></i>
                            </a>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>

    )
}

export default ServicesComponent
import React, { useEffect,useState } from 'react'
import siteSetting from '../../services/settingService';

const TopBarComponent = () => {
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(()=>{
    
        const settingData= async()=>{
            setIsLoading(true);
            const sitedata = await siteSetting()
            if(sitedata) {
                setData(sitedata) ;
                setIsLoading(false);
            }else{
                setIsLoading(false);
            }

        }
        settingData()
    },[])

    // console.log(data)
    return (

        <div className="container-fluid py-2 border-bottom d-none d-lg-block">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 text-center text-lg-start mb-2 mb-lg-0">
                        <div className="d-inline-flex align-items-center">
                            {
                                data && data.site_phone &&
                                <a className="text-decoration-none text-body pe-3"  href={`tel:${data.site_phone ? data.site_phone : ""}`}>
                                    <i className="bi bi-telephone me-2"></i>{data.site_phone ? data.site_phone : ""}</a>
                            }
                            
                            <span className="text-body">|</span>

                            {
                                 data && data.site_email &&
                            <a className="text-decoration-none text-body px-3"  href={`tel:${data.site_email ? data.site_email : ""}`}>
                                <i className="bi bi-envelope me-2"></i>{data.site_email ? data.site_email : ""}</a>

                            }
                        </div>
                    </div>
                    <div className="col-md-6 text-center text-lg-end">
                        <div className="d-inline-flex align-items-center">
                            {
                                   data && data.site_fb_link &&
                                   <a className="text-body px-2" href={`${data.site_fb_link ? data.site_fb_link : ""}`} target="_blank">
                                   <i className="fab fa-facebook-f"></i>
                               </a>
                            }

                           
                           {
                               data && data.site_lin_link &&
                               
                            <a className="text-body px-2" href={`${data.site_lin_link ? data.site_lin_link : ""}`} target="_blank">
                            <i className="fab fa-linkedin-in"></i>
                        </a>
                           }

{
                               data && data.site_tw_link &&
                               
                            <a className="text-body px-2" href={`${data.site_tw_link ? data.site_tw_link : ""}`} target="_blank">
                            <i className="fab fa-twitter"></i>
                        </a>
                           }

                           {
                             data && data.site_in_link &&
                             <a className="text-body px-2"href={`${data.site_in_link ? data.site_in_link : ""}`}>
                                <i className="fab fa-instagram"></i>
                            </a>

                           }
                       
                            
                      
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default TopBarComponent
import React, { useEffect, useState } from "react";
import { imgUrl } from "../components/common/linkUrl";
import aboutService from "../services/aboutServices";

const AboutUsPage = () => {
  const [about, setAbout] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);

    const aboutData = async () => {
      setIsLoading(true);
      const about_data = await aboutService();
      if (about_data) {
        setAbout(about_data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    aboutData();
  }, []);

  return (
    <>
      <div className="container-fluid py-5">
        <div className="container">
          <div className="row gx-5">
            <div
              className="col-lg-5 mb-5 mb-lg-0"
              style={{ maxHeight: "500px" }}
            >
              <div className="position-relative h-100">
                {about && about.about_img && (
                  <img
                    className="position-absolute w-100 h-100 rounded"
                    src={about.about_img ? `${imgUrl}${about.about_img}` : ""}
                    style={{ objectFit: "cover" }}
                  />
                )}

                {/* <img className="position-absolute w-100 h-100 rounded" src="/assets/img/about.jpg" style={{ objectFit: "cover" }} /> */}
              </div>
            </div>
            <div className="col-lg-7">
              <div className="mb-4">
                <h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">
                  About Us
                </h5>
                {about && about.about_title && (
                  <h1 className="display-4">{about.about_title}</h1>
                )}
              </div>
              {about && about.about_des && (
                <>
                  <span
                    className="mb-4"
                    dangerouslySetInnerHTML={{ __html: about.about_des }}
                  ></span>
                </>
              )}
   
            </div>
          </div>

          <div className="row mt-5 shadow p-2">
            <div className="col-md-6" style={{ backgroundColor: "#d9e5ed" }}>
              <div className="mb-4">
                {about && about.ceo_msg && (
                  <>
                    <h4
                      className="d-inline-block text-primary text-uppercase border-5"
                      style={{ borderBottom: "2px solid #3f5ae1" }}
                    >
                      Message From Us
                    </h4>
                    <img
                      className="rounded mx-auto d-block"
                      src={about.ceo_img ? `${imgUrl}${about.ceo_img}` : ""}
                      style={{ objectFit: "cover" }}
                      width="100"
                    />

                    <p
                      className="mb-4 text-justify text-dark"
                      dangerouslySetInnerHTML={{ __html: about.ceo_msg }}
                    ></p>
                  </>
                )}
              </div>
            </div>
            <div className="col-md-6">
            {about && about.mission && (
                  <>
                    <h4
                      className="d-inline-block text-primary text-uppercase border-5"
                      style={{ borderBottom: "2px solid #3f5ae1" }}
                    >
                      Mission

                    </h4>

                    <p
                      className="mb-4 text-justify text-dark"
                      dangerouslySetInnerHTML={{ __html: about.mission }}
                    ></p>
                  </>
                )}


{about && about.vision
 && (
                  <>
                    <h4
                      className="d-inline-block text-primary text-uppercase border-5"
                      style={{ borderBottom: "2px solid #3f5ae1" }}
                    >
                      Vision


                    </h4>

                    <p
                      className="mb-4 text-justify text-dark"
                      dangerouslySetInnerHTML={{ __html: about.vision
                      }}
                    ></p>
                  </>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsPage;

import React, { useEffect, useState } from 'react'
import { imgUrl } from '../components/common/linkUrl';
import servicDataService from '../services/ourServices';


const ServicesPage = () => {
    
    const [services, setServices] = useState();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(()=>{
        window.scrollTo(0, 0);
        const servicesData= async()=>{
            setIsLoading(true);
            const service = await servicDataService()
            if(service) {
                setServices(service) ;
                setIsLoading(false);
            }else{
                setIsLoading(false);
            }

        }
        servicesData()
    },[])
    return (
        <>
            <div className="container-fluid py-5">
                <div className="container">
                    <div className="text-center mx-auto mb-5" style={{ maxWidth: "500px" }}>
                        <h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">Services</h5>
                        <h1 className="display-4">Excellent Dental Services</h1>
                    </div>
                    <div className="row g-5">
                    {
      services && services.length > 0 && services.map((service, idx) => (
        <div className="col-lg-4 col-md-6 " key={idx}>
        <div className="service-item shadow bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
            <div className="mb-4">
            <img src={`${imgUrl}${service.service_img}`} alt="dental service" height='100%' width='100%'/>

            </div>
            <h4 className="mb-3">{service.service_name}</h4>
          
        </div>
    </div>

      ))
}
     
                       
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServicesPage
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { linkUrl } from '../components/common/linkUrl';
import MessageBox from '../components/common/MessageBox';
import siteSetting from '../services/settingService';


const ContactPage = () => {
    const [contact, setContact] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [name, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(null);
    const [succmesg, setSuccmesg] = useState(null);

    useEffect(()=>{
        window.scrollTo(0, 0);
    
        const settingData= async()=>{
            setIsLoading(true);
            const sitedata = await siteSetting()
            if(sitedata) {
                setContact(sitedata) ;
                setIsLoading(false);
            }else{
                setIsLoading(false);
            }

        }
        settingData()
    },[])

    const submitValue=async(e)=>{
        e.preventDefault();
        const contactForm = {
            name,
            email,
            subject,
            message
        }
        if (contactForm !== null) {
            try {
                const res = await axios.post(`${linkUrl}/contactus`, contactForm);
                setFullName("");
                setEmail("");

                setSubject("");
                setMessage("");

                if (res.data.status === 200) {

                    setIsLoading(false);
                    setSuccmesg("We Receive your message")
                    //  setOffers(res.data.data[0]);
                    setError(null);
                }

            } catch (err) {

                setError("Something went worng ");

                // setOffers(null);
            }
            finally {
                setIsLoading(false);
            }
        }
        setFullName("");
        setEmail("");

        setSubject("");
        setMessage("");

    }
  return (
    <>
          {/* <TopBarComponent />
            <NavBarComponent /> */}
        
  
    <div className="container-fluid pt-5">
        <div className="container">
            <div className="text-center mx-auto mb-5" style={{maxWidth: "500px"}}>
                <h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">Any Questions?</h5>
                <h1 className="display-4">Please Feel Free To Contact Us</h1>
            </div>
            <div className="row g-5 mb-5">
                <div className="col-lg-4">
                    <div className="bg-light rounded d-flex flex-column align-items-center justify-content-center text-center" style={{height:"200px"}}>
                        <div className="d-flex align-items-center justify-content-center bg-primary rounded-circle mb-4" style={{width:"100px",height:"70px",transform: "rotate(-15deg)"}}>
                            <i className="fa fa-2x fa-location-arrow text-white" style={{transform: "rotate(15deg)"}}></i>
                        </div>
                     
                        <h6 className="mb-0">   {contact && contact.site_address ? contact.site_address : ""}</h6>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="bg-light rounded d-flex flex-column align-items-center justify-content-center text-center" style={{height:"200px"}}>
                        <div className="d-flex align-items-center justify-content-center bg-primary rounded-circle mb-4" style={{width:"100px",height:"70px",transform: "rotate(-15deg)"}}>
                            <i className="fa fa-2x fa-phone text-white"  style={{transform: "rotate(15deg)"}}></i>
                        </div>
                        <h6 className="mb-0">{contact && contact.site_phone ? contact.site_phone : ""}</h6>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="bg-light rounded d-flex flex-column align-items-center justify-content-center text-center" style={{height:"200px"}}>
                        <div className="d-flex align-items-center justify-content-center bg-primary rounded-circle mb-4" style={{width:"100px",height:"70px",transform: "rotate(-15deg)"}}>
                            <i className="fa fa-2x fa-envelope-open text-white"  style={{transform: "rotate(15deg)"}}></i>
                        </div>
                        <h6 className="mb-0">{contact && contact.site_email ? contact.site_email : ""}</h6>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12" style={{height: "500px"}}>
                    <div className="position-relative h-100">
                        <iframe className="position-relative w-100 h-100"
                            src={contact && contact.site_map ? contact.site_map : ""}
                            frameBorder="0" style={{border:"0"}} allowFullScreen="" aria-hidden="false"
                            tabIndex="0"></iframe>
                    </div>
                </div>
            </div>  
          
            <div className="row justify-content-center position-relative" style={{marginTop:"-200px" , zIndex:"1"}}>
                <div className="col-lg-8">
                    <div className="bg-white rounded p-5 m-5 mb-0">
                    {
                                        error ? (
                                            <MessageBox varient="danger">{error}</MessageBox>
                                        ) : ""

                                    }

                                    <>
                                        {
                                            succmesg ? (
                                                <MessageBox varient="success">{succmesg}</MessageBox>
                                            ) : ""

                                        }
                                    </>
                        <form onSubmit={submitValue}>
                            <div className="row g-3">
                                <div className="col-12 col-sm-6">
                                    <input type="text" 
                                    className="form-control bg-light border-0"
                                    name="name"
                                    value={name}
                                    onChange={e => setFullName(e.target.value)}
                                     placeholder="Your Name" 
                                     style={{height: "55px"}}
                                     required
                                     />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <input type="email" 
                                      name="email"
                                      value={email}
                                      onChange={e => setEmail(e.target.value)}
                                    className="form-control bg-light border-0" 
                                    placeholder="Your Email"
                                     style={{height: "55px"}}
                                     required
                                     />
                                </div>
                                <div className="col-12">
                                    <input type="text" 
                                    name="subject"
                                    value={subject}
                                    onChange={e => setSubject(e.target.value)}
                                    className="form-control bg-light border-0" 
                                    placeholder="Subject"
                                     style={{height: "55px"}}
                                     
                                     />
                                </div>
                                <div className="col-12">
                                    <textarea 
                                    className="form-control bg-light border-0"
                                    name="message"
                                    value={message}
                                    onChange={e => setMessage(e.target.value)}
                                     rows="5" placeholder="Message"
                                     ></textarea>
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>


    </>
  )
}

export default ContactPage
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { linkUrl } from '../components/common/linkUrl';
import { imgUrl } from '../components/common/linkUrl';

const TeamPage = () => {

    const [teams, setTeams] = useState();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(()=>{
        window.scrollTo(0, 0);
        const TeamsData= async()=>{
            setIsLoading(true);
            const teams_data = await axios.get(`${linkUrl}/alldoctors`);
            if (teams_data.data.status === 200) {
              setTeams(teams_data.data.data)
              setIsLoading(false);
       
            }else{
                setIsLoading(false);
            }

        }
        TeamsData()
    },[])
  return (
    <>
    <div className="container-fluid py-5">
     
        <div className="container">
            <div className="text-center mx-auto mb-5" style={{maxWidth: "500px"}}>
                <h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">Our Doctors</h5>
                <h1 className="display-4">Qualified Healthcare Professionals</h1>
            </div>
            <div className="row g-4">

            {
                teams && teams.length > 0 && teams.map((t,idx) => (
                    <div className="col-lg-3 col-md-6  d-flex align-items-stretch " key={idx}>
                    <div className="team-item position-relative rounded overflow-hidden bg-light" >
                        <div className="overflow-hidden">
                            {
                                t.doc_img ?
                            <img className="img-fluid" src={`${imgUrl}${t.doc_img}`}alt=""/>
                                :
                            <img className="img-fluid" src="assets/img/team-1.jpg" alt=""/>


                            }
                        </div>
                        <div className="team-text bg-light text-center p-4">
                            <h5>{t.doc_name?t.doc_name:""}</h5>
                            <p className="text-primary mb-1">{t.position?t.position:""}</p>
                            <p className="text-primary mb-1">{t.department?t.department:""}</p>

                            {
                                t.nmc &&
                            <p className="text-primary">{`N.M.C.No: ${t.nmc?t.nmc:""}`}</p>

                            }
                     
                        </div>
                    </div>
                </div>

                ))
            }
                
              
            </div>

        </div>
    </div>
    </>
  )
}

export default TeamPage
import React from 'react'
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom';
import AppointmentComponent from './components/common/AppointmentComponent';
import FooterComponent from './components/common/FooterComponent';
import NavBarComponent from './components/common/NavBarComponent';
import TopBarComponent from './components/common/TopBarComponent';
import AboutUsPage from './pages/AboutUsPage';
import ContactPage from './pages/ContactPage';
import HomePage from './pages/HomePage';
import PageNotFound from './pages/PageNotFound';
import ServicesPage from './pages/ServicesPage';
import TeamPage from './pages/TeamPage';

const MyRoute = () => {
    return (
        <>
            <Router>
                <TopBarComponent/>
                <NavBarComponent/>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/about-us" element={<AboutUsPage />} />
                    <Route path="/services" element={<ServicesPage />} />
                    <Route path="/teams" element={<TeamPage/>}/>
                    <Route path="/contact" element={<ContactPage/>}/>
                    <Route path="/appointment" element={<AppointmentComponent/>}/>
                    <Route path="*" element={<PageNotFound />} />

                </Routes>
                <FooterComponent/>
            </Router>
        </>
    )
}

export default MyRoute
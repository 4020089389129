import MyRoute from "./MyRoute";



function App() {
  return (
    <div>
      <MyRoute />
    </div>
  );
}

export default App;

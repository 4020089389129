import axios from 'axios';
import React, { useEffect, useState } from 'react'
import appointmentService from '../../services/appointmentServices';
import { linkUrl } from './linkUrl';
import MessageBox from './MessageBox';

const AppointmentComponent = () => {

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [appoDate, setAppoDate] = useState('');
    const [phone, setphone] = useState('');


    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [succmesg, setSuccmesg] = useState(null);
    const [appoDetail, setAppoDetail] = useState();


    useEffect(()=>{
    
        const appointmentData= async()=>{
            setLoading(true);
            const service = await appointmentService()
            if(service) {
                setAppoDetail(service) ;
                setLoading(false);
            }else{
                setLoading(false);
            }

        }
        appointmentData()
    },[])

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
   
    const disablePastDate = () => {
        const today = new Date();
        const dd = String(today.getDate() + 1).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };




    const submitValue = async (e) => {

        e.preventDefault();
        const appointmentForm = {
            fullName,
            email,
            appoDate,
            phone,
      
        }



        if (appointmentForm !== null) {
            try {
                const res = await axios.post(`${linkUrl}/appointment`, appointmentForm);
                setFullName("");
                setEmail("");
                setAppoDate("");
                setphone("");
                

                if (res.data.status === 200) {
                    setLoading(false);
                    setSuccmesg("We Receive Your Appointment Request")
                    //  setOffers(res.data.data[0]);
                    setError(null);
                }

            } catch (err) {

                setError("Something went worng or Appointment date not found");

                // setOffers(null);
            }
            finally {
                setLoading(false);
            }
        }

        setFullName("");
        setEmail("");
        setAppoDate("");
        setphone("");
       



    }

    return (

        <div className="container-fluid bg-primary my-5 py-5"> 
        {/* {console.log(appoDetail)} */}
            <div className="container py-5">
                <div className="row gx-5">
                    <div className="col-lg-6 mb-5 mb-lg-0">
                        <div className="mb-4">
                            <h5 className="d-inline-block text-white text-uppercase border-bottom border-5">Appointment</h5>
                            {
                                appoDetail && appoDetail.appointment_title &&
                                <h1 className="display-4">{appoDetail.appointment_title}</h1>

                            }
                        </div>
                        {
                             appoDetail && appoDetail.appointment_des &&
                        <p className="text-white mb-5" dangerouslySetInnerHTML={{ __html: appoDetail.appointment_des }}></p>


                        }
                        {/* <a className="btn btn-dark rounded-pill py-3 px-5 me-3" href="">Find Doctor</a>
                        <a className="btn btn-outline-dark rounded-pill py-3 px-5" href="">Read More</a> */}
                    </div>
                    <div className="col-lg-6">
                        <div className="bg-white text-center rounded p-5">
                            <h1 className="mb-4">Book An Appointment</h1>

                            {
                                    error ? (
                                        <MessageBox varient="danger">{error}</MessageBox>
                                    ) : ""

                                }

                                <>
                                    {
                                        succmesg ? (
                                            <MessageBox varient="success">{succmesg}</MessageBox>
                                        ) : ""

                                    }
                                </>
                            <form onSubmit={submitValue}>
                                <div className="row g-3">

                                    <div className="col-12 col-sm-6">
                                        <input type="text"
                                         className="form-control bg-light border-0"
                                          placeholder="Your Name" style={{ height: "55px" }} 
                                          name="fullName"
                                          value={fullName}
                                          onChange={e => setFullName(e.target.value)} required
                                          />
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <input type="email"
                                        name="email"
                                        value={email}
                                         className="form-control bg-light border-0"
                                          placeholder="Your Email"
                                           style={{ height: "55px" }} onChange={e => setEmail(e.target.value)} required />
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <div className="date" id="date" data-target-input="nearest">
                                        <input id="startDate" className="form-control bg-light border-0"
                                         name="appoDate" 
                                          min={disablePastDate()}
                                               value={appoDate}
                                               onChange={e => setAppoDate(e.target.value)}
                                               type="date" style={{ height: "55px" }}  required/>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <div className="time" id="time" data-target-input="nearest">
                                            <input type="number"
                                             className="form-control bg-light border-0"
                                              placeholder="Your Contact" style={{ height: "55px" }} 
                                              name="phone"
                                              value={phone}
                                              onChange={e => setphone(e.target.value)} 
                                              required
                                              />

                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-primary w-100 py-3" type="submit">Make An Appointment</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default AppointmentComponent
import axios from "axios";

import { linkUrl } from "../components/common/linkUrl";

const servicDataService = async () => {
    const res = await axios.get(`${linkUrl}/services`);
    if (res.data.status === 200) {
        return res.data.data;
    }
}

export default servicDataService;

import React, { useEffect, useState } from 'react'
import { Link,NavLink } from 'react-router-dom'
import servicDataService from '../../services/ourServices'
import siteSetting from '../../services/settingService'
import { imgUrl } from './linkUrl'

const NavBarComponent = () => {
    const [siteData, setSiteData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(()=>{
       
        const servicesData= async()=>{
            setIsLoading(true);
            const service = await siteSetting()
            if(service) {
                setSiteData(service) ;
                setIsLoading(false);
            }else{
                setIsLoading(false);
            }

        }
        servicesData()
    },[])
    return (


        <div className="container-fluid sticky-top bg-white shadow-sm">
            <div className="container">
                <nav className="navbar navbar-expand-lg bg-white navbar-light py-3 py-lg-0">
                    <Link to="/" className="navbar-brand">
                    <img className="img-fluid" style={{ maxHeight: "70px" }} src={siteData && siteData.site_logo && siteData.site_logo !== null ? `${imgUrl}${siteData.site_logo}` : ""} />
                    <span  style={{ color: ' #00b3f0' }}>{siteData && siteData.site_title ? siteData.site_title : ""}</span>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <div className="navbar-nav ms-auto py-0">
                            <NavLink to="/" className={({isActive})=>'nav-item nav-link'+(isActive ? ' active': '')} end>Home</NavLink>
                            <NavLink to="/about-us" className={({isActive})=>'nav-item nav-link'+(isActive ? ' active': '')}>About</NavLink>
                            <NavLink to="/services" className={({isActive})=>'nav-item nav-link'+(isActive ? ' active': '')}>Service</NavLink>
                            <NavLink to="/teams" className={({isActive})=>'nav-item nav-link'+(isActive ? ' active': '')}>Team</NavLink>
                            <NavLink to="/contact" className={({isActive})=>'nav-item nav-link'+(isActive ? ' active': '')}>Contact</NavLink>

                        </div>
                        
                        <Link to="/appointment" className="btn btn-primary   ms-3">Appointment</Link>
                    </div>
                </nav>
            </div>
        </div>

    )
}

export default NavBarComponent
import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import { linkUrl } from '../common/linkUrl';

const SliderComponent = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    const sliderData= async()=>{
      setIsLoading(true);
      const res = await axios.get(`${linkUrl}/slider`);
      if (res.data.status === 200) {
          setData(res.data.data) ;
          setIsLoading(false);
      }
      else{
          setIsLoading(false);
      }

  };
  sliderData()


  },[])



    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        autoplay: true,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };

    return (
        <div className="container-fluid p-0">
        <Slider {...settings}>
          {
            data && data.sliders.length > 0 && data.imgurl &&
            data.sliders.slice(0,3).map((s,idx)=>(
              <div key={idx}>
              <img src={`${data.imgurl}/${s.slider_img}`} className='img-fluid custom-slide' width="100%" height="50%" alt='' />
             </div>

            ))

          }
    
        </Slider>


        </div>



    )
}

export default SliderComponent

